 #username {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .08em solid transparent; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .04em; /* Adjust as needed */
    margin-bottom: 15px;
    animation: 
      typing 2.5s steps(40),
      blink-caret 1.5s step-end;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    10% { border-color: transparent; }
    20% { border-color: transparent; }
    30% { border-color: transparent; }
    40% { border-color: transparent; }
    50% { border-color: transparent; }
    60% { border-color: transparent; }
    70% { border-color: transparent; }
    80% { border-color: transparent; }
    90% { border-color: transparent; }
    100% { border-color: transparent; }
  }